import axios from 'axios'

const apiService = axios.create({
  baseURL: import.meta.env.VITE_API_URL
})

// Add a request interceptor to attach the token to the headers
apiService.interceptors.request.use(
  (config) => {
    // Attach token to the header if it exists
    config.headers['x-memberstack-token'] = window.localStorage.getItem('_ms-mid')
    config.headers['x-bruqi-class-name'] = window.localStorage.getItem('_bq-cn')
    if (window.localStorage.getItem('_bq-va')) {
      config.headers['x-bruqi-view-as'] = window.localStorage.getItem('_bq-va')
    }
    return config
  },
  (error) => {
    // Handle request error
    return Promise.reject(error)
  }
)

// Add a response interceptor to return response.data directly
apiService.interceptors.response.use(
  (response) => response.data, // Return only the data part of the response
  (error) => {
    return Promise.reject(error?.response?.data || error)
  }
)

export default apiService
import posthog from 'posthog-js'
import { mode } from '@/config'

const key = {
  production: 'phc_HGDDQpEnTLm3bYitwnC2SJimcGmHnz4r7rU2IJBpEAv',
  staging: 'phc_5NjMNUVpHxQh6mGYhmc2EFuW6CiAqZnrY3dEymdouHx',
  development: 'phc_tSA3UyM1UIUT85mGOUTO0JnQxRXWfCXHPhSzP7s9YdI'
}[mode]

posthog.init(key, {
  api_host: 'https://us.i.posthog.com',
  persistence: 'localStorage+cookie',
  opt_out_capturing_by_default: true,
  opt_out_persistence_by_default: true
})

export default posthog
